//
// Form Wizard
//

// twitter-bs-wizard

.twitter-bs-wizard {

    .twitter-bs-wizard-nav {
        position: relative;

        &:before {
            content: "";
            width: 100%;
            height: 2px;
            background-color:var(--#{$prefix}border-color);
            position: absolute;
            left: 0;
            top: 26px;
        }

        .step-number {
            display: inline-block;
            width: 38px;
            height: 38px;
            line-height: 34px;
            border: 2px solid $primary;
            color: $primary;
            text-align: center;
            border-radius: 50%;
            position: relative;
            background-color: $card-bg;

            @media (max-width: 991.98px) {
                display: block;
                margin: 0 auto 8px !important;
            }
        }

        .nav-link {
            .step-title {
                display: block;
                margin-top: 8px;
                font-weight: $font-weight-bold;

                @media (max-width: 575.98px) {
                    display: none;
                }
            }

            &.active {
                background-color: transparent;
                color: var(--#{$prefix}body-color);
            }
        }
    }

    .twitter-bs-wizard-pager-link {
        padding-top: 24px;
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;

        li {
            display: inline-block;

            a {
                display: inline-block;
                padding: .47rem .75rem;
                background-color: $primary;
                color: $white;
                border-radius: .25rem;
            }

            &.disabled {
                a {
                    cursor: not-allowed;
                    background-color: lighten($primary, 8%);
                }
            }

            &.next {
                float: right;
            }
        }
    }

    .step {
        &.active{
            .step-number{
                background-color: var(--#{$prefix}primary);
                color: var(--#{$prefix}white);
            }
        }
    }
    .content {
        margin-top: 0;
        padding: 0;
      }
    
      .stepper-body{ 
        margin-bottom: 0 !important;
      }
}

.twitter-bs-wizard-tab-content {
    padding-top: 24px;
    min-height: 262px;
}

// verticl wixard
.vertical-wizard {

    .stepper {
      display: flex;
  
      .stepper-header {
        flex-shrink: 0;
  
        .steps {
          flex-direction: column;
          padding: 0;
  
          .step {
            min-width: 390px;
            display: block;
            padding: 0.5rem 1rem;
            color: var(--#{$prefix}gray-600);
            font-weight: $font-weight-semibold;
            background-color: rgba($primary, .1);
            text-align: start !important;
  
            .number {
              display: inline-block;
              width: 38px;
              height: 38px;
              line-height: 34px;
              border: 2px solid $primary;
              color: $primary;
              text-align: center;
              border-radius: 50%;
              margin-right: 0.5rem;
            }
  
            &.active {
              background-color: rgba($primary, .2);
              color: var(--#{$prefix}gray-600);
  
              .number {
                background-color: $primary;
                color: #fff;
              }
            }
          }
        }
      }
  
      .stepper-body {
        width: 100%;
        flex-grow: 1;
        margin: 0;
      }
    }
  
    // step
    .steps {
      >ul {
        flex-direction: column;
  
        >li {
          width: 100% !important;
        }
  
      }
    }
  
    .steps,
    .content,
    .actions {
      width: 100%;
      margin-top: 0;
      padding: 0;
    }
  
    .steps {
      @media (min-width: 1200px) {
        width: 25%;
      }
    }
  }